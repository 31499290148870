(function () {
	'use strict';

	angular
		.module('common')
		.factory('utilities', [
			'$sce',
			'$http',
			'$window',
			'$timeout',
			'$location',
			'$compile',
			'$rootScope',
			'$controller',
			'$translate',
			'seedcodeCalendar',
			'firebaseIO',
			'urlParameters',
			'environment',
			utilities,
		]);

	function utilities(
		$sce,
		$http,
		$window,
		$timeout,
		$location,
		$compile,
		$rootScope,
		$controller,
		$translate,
		seedcodeCalendar,
		firebaseIO,
		urlParameters,
		environment
	) {
		var formatStringChunkCache = {};
		var watcherIDNumber; //Global for watchers. We don't want to stack watcher requests. Just keep the last one
		var urlIframeElement;

		// Mutation Observer management collection
		var mutationObservers = {};

		// Initialize tooltip management collection
		var tooltipManager = {
			activeTooltipElements: {},
			activeTooltipHoverFlag: false,
			activeTooltipKeepAlive: false,
			registeredCloseFunctions: {},
		};

		//Initialize an iframe we can use for script urls when in FileMaker
		if (getDBKPlatform() === 'dbkfm') {
			urlIframeElement = createURLFrame();
		}

		class requestThrottle {
			constructor(requestsPerSecond) {
				this.requestsPerSecond = requestsPerSecond;
				this.requestCount = 0;
			}

			executeRequest(request) {
				this.requestCount++;
				if (this.requestCount > this.requestsPerSecond) {
					throw new Error(
						`Exceeded ${this.requestsPerSecond} requests per second`
					);
				}
				request();
				window.setTimeout(() => {
					this.requestCount--;
				}, 1000);
			}
		}

		return {
			requestThrottle: requestThrottle,
			formatCustomFieldData: formatCustomFieldData,
			stringToURLSlug: stringToURLSlug,
			stringToID: stringToID,
			stringToClass: stringToClass,
			generateUniquePublicID: generateUniquePublicID,
			generateUID: generateUID,
			generateEventID: generateEventID,
			generatePassword: generatePassword,
			sendEmail: sendEmail,
			sendStatusMessage: sendStatusMessage,
			commaSeparatedToArray: commaSeparatedToArray,

			updateSystemHealth: updateSystemHealth,
			trackMixPanel: trackMixPanel,
			configErrorToSystemHealth: configErrorToSystemHealth,
			messageToSystemHealth: messageToSystemHealth,

			isFMPlatform: isFMPlatform,
			basePlatformMatch: basePlatformMatch,
			getDBKPlatform: getDBKPlatform,

			getBaseURL: getBaseURL,
			help: help,
			getWindowDimensions: getWindowDimensions,
			resizeCalendar: resizeCalendar,
			dataURLMessage: dataURLMessage,
			requiresHTTPS: requiresHTTPS,

			filterSpecialChars: filterSpecialChars,

			arraysEqual: arraysEqual,
			mergeArrays: mergeArrays,
			cloneArrayObjects: cloneArrayObjects,
			removeObjectArrayDupes: removeObjectArrayDupes,
			objectToArray: objectToArray,
			arrayDiff: arrayDiff,
			objectArrayMatch: objectArrayMatch,

			numberToDays: numberToDays,

			daySpan: daySpan,
			workDays: workDays,
			filemakerExternalOauth: filemakerExternalOauth,
			performFileMakerScript: performFileMakerScript,
			fileMakerCall: fileMakerCall,
			scriptURL: scriptURL,
			getFile: getFile,
			getFileOnLoad: getFileOnLoad,
			getCustomFieldIdByName: getCustomFieldIdByName,
			tooltip: tooltip,
			observe: observe,
			popover: popover,
			showMessage: showMessage,
			hideMessages: hideMessages,
			showModal: showModal,

			updateConfig: updateConfig,

			getTimezones: getTimezones,

			getLocalTimeFormat: getLocalTimeFormat,

			generateTimeList: generateTimeList,

			rgbToHex: rgbToHex,
			hexToRgb: hexToRgb,
			generateTextColor: generateTextColor,
			generateColorTransparency: generateColorTransparency,
			generateRandomColor: generateRandomColor,

			scrollToY: scrollToY,
			scrollToX: scrollToX,
			decodeHtmlEntities: decodeHtmlEntities,
			htmlEscape: htmlEscape,
			encodeBrokenTags: encodeBrokenTags,
			eventTitleCalc: eventTitleCalc,

			floatMath: floatMath,
			formatDisplayNumber: formatDisplayNumber,

			getShareScheduleProperties: getShareScheduleProperties,
			getValidShareCustomFields: getValidShareCustomFields,

			humanJoin: humanJoin,
			emailsFromString: emailsFromString,
			mapToArray: mapToArray,
		};

		/**
		 * Decodes html entities by name and number
		 */
		function decodeHtmlEntities(str) {
			return str.replace(/&#?(\w+);/g, function (match, dec) {
				var chars;
				if (isNaN(dec)) {
					chars = {
						quot: 34,
						amp: 38,
						lt: 60,
						gt: 62,
						nbsp: 160,
						copy: 169,
						reg: 174,
						deg: 176,
						frasl: 47,
						trade: 8482,
						euro: 8364,
						Agrave: 192,
						Aacute: 193,
						Acirc: 194,
						Atilde: 195,
						Auml: 196,
						Aring: 197,
						AElig: 198,
						Ccedil: 199,
						Egrave: 200,
						Eacute: 201,
						Ecirc: 202,
						Euml: 203,
						Igrave: 204,
						Iacute: 205,
						Icirc: 206,
						Iuml: 207,
						ETH: 208,
						Ntilde: 209,
						Ograve: 210,
						Oacute: 211,
						Ocirc: 212,
						Otilde: 213,
						Ouml: 214,
						times: 215,
						Oslash: 216,
						Ugrave: 217,
						Uacute: 218,
						Ucirc: 219,
						Uuml: 220,
						Yacute: 221,
						THORN: 222,
						szlig: 223,
						agrave: 224,
						aacute: 225,
						acirc: 226,
						atilde: 227,
						auml: 228,
						aring: 229,
						aelig: 230,
						ccedil: 231,
						egrave: 232,
						eacute: 233,
						ecirc: 234,
						euml: 235,
						igrave: 236,
						iacute: 237,
						icirc: 238,
						iuml: 239,
						eth: 240,
						ntilde: 241,
						ograve: 242,
						oacute: 243,
						ocirc: 244,
						otilde: 245,
						ouml: 246,
						divide: 247,
						oslash: 248,
						ugrave: 249,
						uacute: 250,
						ucirc: 251,
						uuml: 252,
						yacute: 253,
						thorn: 254,
						yuml: 255,
						lsquo: 8216,
						rsquo: 8217,
						sbquo: 8218,
						ldquo: 8220,
						rdquo: 8221,
						bdquo: 8222,
						dagger: 8224,
						Dagger: 8225,
						permil: 8240,
						lsaquo: 8249,
						rsaquo: 8250,
						spades: 9824,
						clubs: 9827,
						hearts: 9829,
						diams: 9830,
						oline: 8254,
						larr: 8592,
						uarr: 8593,
						rarr: 8594,
						darr: 8595,
						hellip: 133,
						ndash: 150,
						mdash: 151,
						iexcl: 161,
						cent: 162,
						pound: 163,
						curren: 164,
						yen: 165,
						brvbar: 166,
						brkbar: 166,
						sect: 167,
						uml: 168,
						die: 168,
						ordf: 170,
						laquo: 171,
						not: 172,
						shy: 173,
						macr: 175,
						hibar: 175,
						plusmn: 177,
						sup2: 178,
						sup3: 179,
						acute: 180,
						micro: 181,
						para: 182,
						middot: 183,
						cedil: 184,
						sup1: 185,
						ordm: 186,
						raquo: 187,
						frac14: 188,
						frac12: 189,
						frac34: 190,
						iquest: 191,
						Alpha: 913,
						alpha: 945,
						Beta: 914,
						beta: 946,
						Gamma: 915,
						gamma: 947,
						Delta: 916,
						delta: 948,
						Epsilon: 917,
						epsilon: 949,
						Zeta: 918,
						zeta: 950,
						Eta: 919,
						eta: 951,
						Theta: 920,
						theta: 952,
						Iota: 921,
						iota: 953,
						Kappa: 922,
						kappa: 954,
						Lambda: 923,
						lambda: 955,
						Mu: 924,
						mu: 956,
						Nu: 925,
						nu: 957,
						Xi: 926,
						xi: 958,
						Omicron: 927,
						omicron: 959,
						Pi: 928,
						pi: 960,
						Rho: 929,
						rho: 961,
						Sigma: 931,
						sigma: 963,
						Tau: 932,
						tau: 964,
						Upsilon: 933,
						upsilon: 965,
						Phi: 934,
						phi: 966,
						Chi: 935,
						chi: 967,
						Psi: 936,
						psi: 968,
						Omega: 937,
						omega: 969,
					};
					if (chars[dec] !== undefined) {
						dec = chars[dec];
					}
				}
				return String.fromCharCode(dec);
			});
		}

		/**
		 * Replaces characters that are not safe for html (used in html) with their encoded equivilants.
		 * @param {string} value - the value to escape html characters from.
		 * @returns {string} The string once it has replaced html unsafe characters.
		 */
		function htmlEscape(value, excludeAmpersand) {
			value = value + '';
			if (!excludeAmpersand) {
				// Excluding ampersands is usefull if the content may already have encoded values like &lt; for example
				value = value.replace(/&/g, '&amp;');
			}
			return value
				.replace(/</g, '&lt;')
				.replace(/>/g, '&gt;')
				.replace(/'/g, '&#039;')
				.replace(/"/g, '&quot;');
		}

		/** @type {(value: string) => string} */
		function encodeBrokenTags(value) {
			if (!value) {
				return '';
			}

			// Find all html tags in a string, both opening and closing as well as self closing
			const htmlTagsRegex = new RegExp('<[^<>]+>', 'g');
			// Find all opening tags in a string, exluding self closing like <br /> or <img />
			const openTagRegex = new RegExp(
				'<([a-zA-Z]+)(?![^>]*/>)[^>]*>',
				'gi'
			);
			// Find all non closing tags like <br>, <img> etc. This will not find tags that are self closed like <br />
			const nonClosingTagRegex = new RegExp(
				'<(br|img|hr|input)(?![^>]*/>)[^>]*>',
				'gi'
			);
			// Find all closing tags in a string excluding self closing
			const closeTagRegex = new RegExp('</[a-zA-Z][-a-zA-Z0-9_]*>', 'gi');

			const htmlTagsArray = value.match(htmlTagsRegex);
			const contentArray = value.split(htmlTagsRegex);
			const open = value.match(openTagRegex);
			const nonClosing = value.match(nonClosingTagRegex);
			const closed = value.match(closeTagRegex);

			const openCount = open?.length ? open.length : 0;
			const nonClosingCount = nonClosing?.length ? nonClosing.length : 0;
			const closedCount = closed?.length ? closed.length : 0;

			for (let i = 0; i < contentArray.length; i++) {
				contentArray[i] = `${htmlEscape(contentArray[i], true)}${
					htmlTagsArray?.[i] ? htmlTagsArray[i] : ''
				}`;
			}
			value = contentArray.join('');

			if (
				(open && !closed) ||
				(closed && !open) ||
				openCount - nonClosingCount !== closedCount
			) {
				const fragment = document.createDocumentFragment();
				const innerElement = document.createElement('div');
				fragment.appendChild(innerElement);
				innerElement.innerHTML = value;
				value = fragment.firstChild.innerHTML;
			}

			return value;
		}

		/** @type {(value: string) => string} */
		function stringToURLSlug(value) {
			var cleanValue = stringToID(value);
			return cleanValue
				? cleanValue.replace(/ /g, '-').toLowerCase()
				: '';
		}

		/**
		 * Removes non javascript / json safe characters from a string so it can be used as an id or object property.
		 * @param {string} value - The value that should be converted to an id.
		 * @returns {string} The string once it has removed special characters.
		 */
		function stringToID(value, alphaNumericOnly) {
			return removeSpecial(value);

			//return true if char is a number
			function isNumber(value) {
				return !isNaN(Number(value));
			}

			function removeSpecial(value) {
				if (!value) {
					return '';
				}

				if (typeof value === 'number') {
					value = value.toString();
				}

				var lower = value.toLowerCase();
				var upper = value.toUpperCase();
				var result = '';
				for (var i = 0; i < lower.length; ++i) {
					if (
						isNumber(value[i]) ||
						lower[i] !== upper[i] ||
						(lower[i] === '-' && !alphaNumericOnly)
					) {
						result += value[i];
					}
				}
				return result;
			}
		}

		function stringToClass(value, prefix) {
			// Convert a complex string to something that is safe for CSS class
			prefix = prefix ? prefix + '-' : '';

			var prefixLength = prefix ? prefix.length : 0;

			if (value && prefix && value.slice(0, prefixLength) === prefix) {
				prefix = '';
			}

			return value ? prefix + stringToID(value.split(' ').join('-')) : '';
		}

		/**
		 * Creates a unique id that could be used for public facing identifiers and urls.
		 * @returns {string} The unique id string.
		 */
		function generateUniquePublicID() {
			var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
			var uid = generateUID();
			var result = uid.replace(/-/g, function (a) {
				return chars[randomised(chars.length)];
			});

			return result;

			function randomised(len) {
				return Math.floor(Math.random() * len);
			}
		}

		/**
		 * Generates a unique id based on current timestamp and a random number.
		 * @returns {string} The unique id.
		 */
		function generateUID() {
			var timeValue = new Date().getTime();
			var randomValue = Math.random().toFixed(10);
			return timeValue + '-' + randomValue.split('.')[1];
		}

		function generateEventID(
			sourceTypeID,
			sourceID,
			eventID,
			depricatedID
		) {
			if (depricatedID) {
				// Depricated event id
				return stringToID(sourceTypeID + '-' + eventID);
			}
			// New event id
			return stringToID(sourceTypeID + '-' + sourceID + '-' + eventID);
		}

		/**
		 * Generates a hard to guess password. Used when a user requests a password reset.
		 * @returns {string} The password string.
		 */
		function generatePassword() {
			var text = ''; //Set to empty string so we don't get undefined in our concatonation
			var possible =
				'ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz0123456789-!#$%';

			for (var i = 0; i < 7; i++)
				text += possible.charAt(
					Math.floor(Math.random() * possible.length)
				);

			return text;
		}

		/**
		 * Callback for email sent.
		 *
		 * @callback emailSentCallback
		 * @param {(object | boolean)} success - An object with success data, or false if an error is returned.
		 */

		/**
		 * Sends an email using campaign monitor.
		 * @param {object} emailProperties - The properties used to send an email.
		 * @param {string} emailProperties.email - The email address of the recipient.
		 * @param {string} emailProperties.tempPassword - A temporary password if this is for a password reset email.
		 * @param {string} emailProperties.firstName - The first name of the recipient.
		 * @param {string} emailProperties.lastName - The last name of the recipient.
		 * @param {string} emailProperties.group - The group name of the recipient. Group name may or not exist.
		 * @param {string} emailProperties.origin - The origin of the recipient. Were they invited, came from salesforce etc.
		 * @param {string} emailProperties.userToken - User token if part of password reset.
		 * @param {emailSentCallback} callback - A callback function when an email is sent.
		 */
		var emailProperties = {
			email: email,
			tempPassword: isTemporaryPassword ? password : '',
			firstName: firstName || '',
			lastName: lastName || '',
			group: groupName || '',
			origin: origin,
			userToken: userToken || '',
		};
		function sendEmail(emailProperties, callback) {
			var data = $.param(emailProperties);
			$http({
				method: 'POST',
				url: 'php/campaign-monitor/user-join.php',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				transformRequest: function (obj) {
					var str = [];
					for (var p in obj)
						str.push(
							encodeURIComponent(p) +
								'=' +
								encodeURIComponent(obj[p])
						);
					return str.join('&');
				},
				data: emailProperties,
			})
				.success(function (data, status, headers, config) {
					if (callback) {
						callback(data);
					}
				})
				.error(function (data, status, headers, config) {
					if (callback) {
						callback(false);
					}
				});
		}

		function sendStatusMessage(message, callback) {
			$http({
				method: 'POST',
				url: _CONFIG.DBK_STATUS_URL,
				headers: {'Content-Type': 'application/json'},
				data: {
					message: message,
				},
			})
				.then(function (response) {
					//Run something after successful post
					if (callback) {
						callback();
					}
				})
				.catch(function (error) {
					//Run something on error
				});
		}

		function commaSeparatedToArray(text, itemAsObject, classPrefix) {
			var result = [];
			var itemObject;
			if (!text) {
				return;
			}
			//Check if we are already an array or not
			if (!Array.isArray(text) && text) {
				text = text.split(',');
			}
			for (var i = 0; i < text.length; i++) {
				if (itemAsObject) {
					itemObject = {};
					itemObject.name = text[i].trim();
					itemObject.class = stringToClass(
						itemObject.name,
						classPrefix
					);
					result.push(itemObject);
				} else {
					result.push(text[i].trim());
				}
			}
			return result;
		}

		async function updateSystemHealth(property, value, isGroupAttribute) {
			const {groupID, userID} = seedcodeCalendar.get('config');

			if (!groupID || !userID) {
				throw 'User not initialized yet';
			}

			const groupPath = `systemHealth/${groupID}`;

			const path = isGroupAttribute
				? `${groupPath}/group`
				: `${groupPath}/users/${userID}`;

			try {
				await firebaseIO.setData(path, property, value);
				await firebaseIO.setData(
					groupPath,
					'lastModified',
					new Date().valueOf()
				);
			} catch (err) {
				throw err;
			}
		}

		/** @type{(message: string) => void} */
		function configErrorToSystemHealth(message) {
			updateSystemHealth('configError', {
				date: new Date().valueOf(),
				message: message,
			});
		}

		/** @type{(messageType: string, additionalProperties: Object) => void} */
		function messageToSystemHealth(messageType, additionalProperties) {
			updateSystemHealth(messageType, {
				date: new Date().valueOf(),
				properties: createSystemHealthProperties(additionalProperties),
			});
		}

		function createSystemHealthProperties(existingProperties) {
			var config = seedcodeCalendar.get('config');
			var view = seedcodeCalendar.get('view');
			var textFilters = seedcodeCalendar.get('textFilters');

			if (!existingProperties) {
				existingProperties = {};
			}
			// If the view object is available we are showing the calendar, otherwise assume we are on settings or another view
			if (view) {
				existingProperties['horizonScale'] = Number(
					view.end.diff(view.start, 'days')
				);
			}
			existingProperties.View = config.defaultView;
			existingProperties['resourceDays'] = Number(config.resourceDays);
			existingProperties['filtersApplied'] = getFiltersApplied();
			existingProperties['analyticsVisible'] = config.showMeasure
				? config.showMeasure.status
				: false;
			existingProperties['platform'] = getDBKPlatform();

			existingProperties['subscriptionState'] = config.activationState
				? config.activationState
				: '';

			existingProperties['isMobile'] = environment.isMobileDevice;

			existingProperties['isPhone'] = environment.isPhone;

			existingProperties['isSafari'] = environment.isSafari;

			existingProperties['isChrome'] = environment.isChrome;

			existingProperties['isStandAlone'] = environment.isStandAlone;

			existingProperties['touchPoints'] = window.navigator.maxTouchPoints;

			if (textFilters) {
				existingProperties['textFilterApplied'] = textFilters;
			}

			return existingProperties;
		}

		function trackMixPanel(eventName, existingProperties) {
			return;
		}

		function isFMPlatform(currentPlatform) {
			if (!currentPlatform) {
				currentPlatform = getDBKPlatform();
			}
			return (
				currentPlatform === 'dbkfmjs' || currentPlatform === 'dbkfmwd'
			);
		}

		function basePlatformMatch(currentPlatform, platformCheck) {
			if (
				((currentPlatform === 'dbkfmjs' ||
					currentPlatform === 'dbkfmwd') &&
					(platformCheck === 'dbkfmjs' ||
						platformCheck === 'dbkfmwd')) ||
				currentPlatform === platformCheck
			) {
				return true;
			}
		}

		function getDBKPlatform() {
			var platform;
			if (urlParameters.type === 'webviewer') {
				platform = 'dbkfm';
			} else if (urlParameters.isWebDirect) {
				platform = 'dbkfmwd';
			} else if (urlParameters.type === 'FileMakerJS') {
				platform = 'dbkfmjs';
			} else if (fbk.isSalesforce()) {
				platform = 'dbksf';
			} else {
				platform = 'dbko';
			}
			return platform;
		}

		function getFiltersApplied() {
			var filtersApplied = [];
			if (isFilterSelected(seedcodeCalendar.get('resources'))) {
				filtersApplied.push('resource');
			}
			if (isFilterSelected(seedcodeCalendar.get('statuses'))) {
				filtersApplied.push('status');
			}
			if (seedcodeCalendar.get('textFilters')) {
				filtersApplied.push('text');
			}

			return filtersApplied;
		}

		function isFilterSelected(filterObject) {
			if (filterObject) {
				for (var filterKey in filterObject) {
					if (filterObject[filterKey].status.selected) {
						return true;
					}
				}
			}
			return false;
		}

		function getBaseURL(forceHTTPS, removeHash) {
			var protocol = forceHTTPS ? 'https:' : window.location.protocol;
			var host = window.location.host;
			var hash = !removeHash ? '#/' : '';
			if (!host) {
				host = 'app.dayback.com';
			}
			return protocol + '//' + host + '/' + hash;
		}

		function help(page, pagesf, fullURL, pageShare) {
			// OLD... page = DayBack page url, pagesf = the page url in our salesforce docs
			// NEW.. page = DayBack for FileMaker page url, pagesf = the page url in our consolidated salesforce& DBkO docs
			var config = seedcodeCalendar.get('config');
			var platform = getDBKPlatform();
			var url;

			if (fullURL) {
				//Return if no url is passed in
				if (!page && !pagesf) {
					return;
				}

				//If pagesf is not set we can set it to page as we may not always need two different url's here
				if (page && !pagesf) {
					pagesf = page;
				}

				//Make sure we have a valid url otherwise it will assume relative link. A colon will prevent relative link so make sure it at least contains a colon
				page = page.indexOf(':') === -1 ? 'http://' + page : page;
				pagesf =
					pagesf.indexOf(':') === -1 ? 'http://' + pagesf : pagesf;
			}

			if (platform === 'dbkfm') {
				if (!page) {
					page = 'DayBackForFileMaker';
				}
				//page needs to be double url encoded because filemaker will unencode it. So the data should be encoded already and we encode again here
				scriptURL(
					'script=' +
						encodeURIComponent('Calendar Help From WebViewer') +
						'&$page=' +
						encodeURIComponent(page) +
						'&$fullURL=' +
						fullURL
				);
			} else if (platform === 'dbkfmjs' || platform === 'dbkfmwd') {
				if (!pagesf) {
					fullURL = true;
					pagesf = 'https://docs.dayback.com';
				}
				url = fullURL
					? pagesf
					: 'https://docs.dayback.com/article/' + pagesf;
				performFileMakerScript('Open URL - DayBack', url, null);
			} else if (platform === 'dbksf') {
				if (!pagesf) {
					fullURL = true;
					pagesf = 'https://docs.dayback.com';
				}
				url = fullURL
					? pagesf
					: 'https://docs.dayback.com/article/' + pagesf;
				if (environment.isStandAlone) {
					fbk.publish('dbk.navigate', {url: url, new: true});
				} else {
					window.open(url);
				}
			} else if (config && config.isShare) {
				if (pageShare) {
					pagesf = pageShare;
				}
				if (!pagesf) {
					//If no document is passed take us to the doc home page
					fullURL = true;
					pagesf = 'https://sharing-docs.dayback.com';
				}
				url = fullURL
					? pagesf
					: 'https://sharing-docs.dayback.com/article/' + pagesf;
				window.open(url);
			} else {
				if (!pagesf) {
					fullURL = true;
					pagesf = 'https://docs.dayback.com';
				}
				// We've now consolidated DBkO and DBkSF into one doc, so we'll use the pagesf entry for DBkO also
				// this leaves "page" just for DBkFM
				url = fullURL
					? pagesf
					: 'https://docs.dayback.com/article/' + pagesf;
				window.open(url);
			}
		}

		function getWindowDimensions() {
			var headerHeight = $('#header').outerHeight();
			var dimensions = {
				windowHeight: $window.innerHeight,
				windowWidth: $window.innerWidth,
				calendarHeight: $window.innerHeight - headerHeight,
			};
			return dimensions;
		}

		function resizeCalendar(delay) {
			$timeout(function () {
				const element = seedcodeCalendar.get('element');
				if (!element) {
					return;
				}
				element.fullCalendar(
					'option',
					'height',
					getWindowDimensions().calendarHeight
				);
			}, delay);
		}

		function dataURLMessage(message) {
			return (
				'data:text/html,<!--================================================================================================================--><!doctype html><html><head><style>html {min-width: 100%; min-height: 100%;} body {background: rgb(75,75,75); font-family: "Helvetica Neue",Helvetica,Arial,sans-serif; height: 100%;} .message {margin: 80px 20px; text-align: center; color: white;} h1 {font-weight: 200; font-size: 36px;} a {color: white;} a:hover {color: rgb(225,225,225);}</style><head><body><h1 class="message">' +
				message +
				'</h1></body></html>'
			);
		}

		function requiresHTTPS(message, callback) {
			var title = 'HTTPS Connection Required';
			var confirmButtonText = 'Reload as HTTPS';
			var cancelButtonText = 'Cancel';

			//Check if page is not currently https
			if (location.protocol !== 'https:') {
				showModal(
					title,
					message,
					cancelButtonText,
					cancelFunction,
					confirmButtonText,
					confirmFunction
				);
			} else {
				callback();
			}

			function confirmFunction() {
				reloadAsHTTPS();
			}

			function cancelFunction() {
				//Some action could go here
				return;
			}

			function reloadAsHTTPS() {
				window.location.href = 'https' + window.location.href.slice(4);
			}
		}

		function filterSpecialChars(text, alphaNumericOnly, convertToChar) {
			// Removes all special characters from a string.
			// Allows letters, numbers, spaces and underscores.
			let result = '';
			if (!text) {
				return;
			}

			if (typeof text === 'number') {
				text = text.toString();
			}

			if (alphaNumericOnly) {
				let lower = text.toLowerCase();
				let upper = text.toUpperCase();
				for (let i = 0; i < lower.length; ++i) {
					if (isNumber(text[i])) {
						result += convertToChar
							? intToChar(Number(text[i]))
							: text[i];
					} else if (lower[i] !== upper[i]) {
						result += text[i];
					}
				}
			} else {
				result = text.replace(/[^\w\s!?]/g, '');
			}

			return result;

			// Returns a letter equivilant for a number
			function intToChar(int) {
				// For Uppercase letters, replace `a` with `A`
				const code = 'a'.charCodeAt(0);

				return String.fromCharCode(code + int);
			}

			//return true if char is a number
			function isNumber(value) {
				return !isNaN(Number(value));
			}
		}

		function arraysEqual(arr1, arr2) {
			if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
				return false;
			}
			if (arr1.length !== arr2.length) {
				return false;
			}
			for (var i = arr1.length; i--; ) {
				if (arr1[i] !== arr2[i]) {
					return false;
				}
			}
			return true;
		}

		function cloneArrayObjects(arrayObjects) {
			var arrayOutput = [];
			var arrayItem;
			if (!arrayObjects || !Array.isArray(arrayObjects)) {
				return [];
			}
			for (var i = 0; i < arrayObjects.length; i++) {
				if (!arrayObjects[i]) {
					continue;
				}

				arrayItem = {};
				for (var property in arrayObjects[i]) {
					if (
						arrayObjects[i][property] !== undefined &&
						property !== '$$hashKey'
					) {
						arrayItem[property] = arrayObjects[i][property];
					}
				}

				arrayOutput.push(arrayItem);
			}

			return arrayOutput;
		}

		function mergeArrays(array1, array2, requiredProperty) {
			var arrClone1 = array1 ? JSON.parse(JSON.stringify(array1)) : [];
			var arrClone2 = array2 ? JSON.parse(JSON.stringify(array2)) : [];
			var result = [];
			var i;

			for (i = 0; i < arrClone1.length; i++) {
				if (arrClone1[i].$$hashKey) {
					delete arrClone1[i].$$hashKey;
				}
				if (!requiredProperty || arrClone1[i][requiredProperty]) {
					result.push(arrClone1[i]);
				}
			}

			for (i = 0; i < arrClone2.length; i++) {
				if (arrClone2[i].$$hashKey) {
					delete arrClone2[i].$$hashKey;
				}
				if (!requiredProperty || arrClone2[i][requiredProperty]) {
					result.push(arrClone2[i]);
				}
			}

			result.sort(function (a, b) {
				return a[requiredProperty].localeCompare(b[requiredProperty]);
			});
			return result;
		}

		function removeObjectArrayDupes(arr, key, preFilter, sort) {
			var values = {};
			var noDupes = arr.filter(function (item, index, inputArray) {
				if (preFilter && !preFilter(item)) {
					return false;
				}
				var val = item[key];
				var exists = values[val];
				values[val] = true;
				return val && !exists;
			});

			if (sort) {
				return noDupes.sort(compare);
			} else {
				return noDupes;
			}

			function compare(a, b) {
				return a[key].localeCompare(b[key]);
			}
		}

		function objectToArray(obj) {
			var result = [];
			if (!obj) {
				return result;
			}

			for (property in obj) {
				result.push(obj[property]);
			}
			return result;
		}

		function arrayDiff(array1, array2) {
			var a = [];
			var diff = [];

			if (typeof array1 !== 'undefined' && array1) {
				for (var i = 0; i < array1.length; i++) {
					a[array1[i]] = a[array1[i]] ? a[array1[i]] + 1 : 1;
				}
			}

			if (typeof array2 !== 'undefined' && array2) {
				for (var c = 0; c < array2.length; c++) {
					if (a[array2[c]]) {
						a[array2[c]] = a[array2[c]] - 1;
						// delete a[array2[c]];
					} else {
						a[array2[c]] = a[array2[c]] ? a[array2[c]] + 1 : 1;
					}
				}
			}

			for (var k in a) {
				if (a[k]) {
					diff.push(k);
				}
			}

			return diff;
		}

		// Return array of objects that match a criteria
		function objectArrayMatch(matchItems, listItems, property, contains) {
			// Variable assignments
			var listItem;
			var matchItem;
			var result = [];

			// Exit if parameters are not correct
			if (
				!matchItems ||
				!matchItems.length ||
				!listItems ||
				!listItems.length ||
				!property
			) {
				return;
			}

			// Loop through all list items (array of objects) and return a matching array
			for (var i = 0; i < matchItems.length; i++) {
				matchItem = matchItems[i];
				for (var ii = 0; ii < listItems.length; ii++) {
					listItem = listItems[ii];
					if (
						listItem[property] === matchItem ||
						(contains && listItem[property].indexOf(matchItem) > -1)
					) {
						result.push(listItem);
					}
				}
			}
			return result;
		}

		//Date functions
		function numberToDays(time) {
			return Math.round(Number(time) / (1000 * 60 * 60 * 24));
		}

		// Date utilities
		function daySpan(dateStart, dateEnd, preventNegative) {
			if (preventNegative) {
				if (durationLabel === 'now') {
					if (input.isBefore(compareMoment)) {
						diff = compareMoment.diff(input, durationFormat);
						textDirection = 'before';
					} else {
						diff = input.diff(compareMoment, durationFormat);
						textDirection = 'from';
					}
				} else {
					diff = compareMoment.diff(input, durationFormat);
					textDirection = '';
				}
			}
		}

		function workDays(dateStart, dateEnd, dayback) {
			dateStart = moment(dateStart).startOf('day');
			dateEnd = moment(dateEnd).startOf('day');

			//var millisecondsPerDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
			var dateStartObject = new Date(dateStart);
			var dateEndObject = new Date(dateEnd);
			var daySpan;
			var startDay;
			var endDay;

			// Validate input and calculate days between dates

			if (dateStart.isBefore(dateEnd)) {
				daySpan = dateEnd.diff(dateStart, 'days'); // Milliseconds between dates
				startDay = dateStart.day();
				endDay = dateEnd.day();
			} else {
				daySpan = dateStart.diff(dateEnd, 'days'); // Milliseconds between dates
				startDay = dateEnd.day();
				endDay = dateStart.day();
			}

			// Subtract two weekend days for every week in between
			var weeks = Math.floor(daySpan / 7);
			daySpan = daySpan - weeks * 2;

			// Remove weekend not previously removed.
			if (startDay - endDay >= 1 && endDay > 0) {
				daySpan -= 2;
			}
			//Remove end day if lands on Sunday
			if (startDay !== 0 && endDay === 0) {
				daySpan--;
			}
			//Remove start day if lands on sunday
			if (startDay === 0 && endDay > 0) {
				daySpan--;
			}

			//If there is a dayback selected we remove it from working days too (ignore setting if disabled or set to a weekend day.)
			if (dayback && dayback > 0 && dayback < 6) {
				//Remove dayback for each week
				daySpan -= weeks;
				//Remove another day for dayback not previously removed
				if (
					startDay - endDay >= 1 &&
					startDay > dayback &&
					endDay <= dayback
				) {
					//We do nothing if the start day is after the dayback day and the end day is before the dayback day.
				} else if (
					startDay - endDay >= 1 &&
					(startDay <= dayback || endDay >= dayback)
				) {
					daySpan--;
				} else if (
					startDay >= 0 &&
					startDay <= dayback &&
					(endDay > dayback || endDay === 0) &&
					startDay !== endDay
				) {
					daySpan--;
				}
			}

			return Math.max(0, daySpan);
		}

		function filemakerExternalOauth(
			platform,
			authURL,
			searchTerm,
			callback
		) {
			var modalTitle;
			var modalMessage;

			if (platform === 'dbkfmjs') {
				performFileMakerScript(
					'Modal Webviewer - DayBack',
					{url: authURL, searchTerm: searchTerm, checkDelay: 1},
					function () {
						callback();
					},
					true
				);
			} else {
				modalTitle = 'Waiting for Authentication';
				modalMessage =
					'Authentication taking place in a new window. Choose an option below once complete.';
				showModal(
					modalTitle,
					modalMessage,
					'Cancel',
					null,
					"I've Authenticated",
					callback
				);
				performFileMakerScript('Open URL - DayBack', authURL, null);
			}
		}

		function performFileMakerScript(
			script,
			parameter,
			callback,
			directCall
		) {
			// Script = FileMaker script name as a string
			// Parameter = parameter string, can be object and then could be parsed in FileMaker
			// Callback = callback function to run after FileMaker script is complete
			// DirectCall = Boolean if set to true will run a FileMaker script directly based on passed script name rather than through another proxy script

			var callbackID;
			var request = {};

			var payload = {};
			payload.script = directCall
				? script
				: 'Script From Webviewer - DayBack';
			payload.dbk = true;

			if (!directCall) {
				// Set script in request if this isn't a direct call
				request.script = script;

				// Set request if parameter exists
				if (parameter) {
					request.param = parameter;
				}
			} else {
				request = parameter;
			}

			payload.request = request;

			// Define callback: if this isn't a direct call always assume callback so our parent FM script works the same way every time
			if (callback || !directCall) {
				callbackID = generateUID();
				dbk_fmFunctions[callbackID] = function (result) {
					if (result) {
						try {
							result.payload = JSON.parse(result.payload);
						} catch (error) {
							result.payload = result.payload;
						}
					}
					if (callback) {
						callback(result);
					}
				};
				payload.callback = callbackID;
			}

			fileMakerCall(payload);
		}

		function fileMakerCall(payload, retry) {
			//calls FileMaker.PerformScript either directly or via the parent window
			//if WebDirect. FileMaker object can sometimes throw a Missine error, so put
			//in Try Catch Block
			var payloadString;
			retry = retry ? retry : 0;
			var payloadParams = JSON.parse(JSON.stringify(payload));

			// Delete the script name from payload params as it isn't needed there
			delete payloadParams.script;
			delete payloadParams.dbk;

			if (
				payloadParams &&
				Object.keys(payloadParams).length === 1 &&
				payloadParams.request
			) {
				payloadString = JSON.stringify(payloadParams.request);
			} else {
				payloadString =
					payloadParams && Object.keys(payloadParams).length
						? JSON.stringify(payloadParams)
						: '';
			}

			if (
				getDBKPlatform() &&
				getDBKPlatform() === 'dbkfmjs' &&
				getDBKPlatform() !== 'dbkfmwd'
			) {
				//calling from FileMaker client
				try {
					if (FileMaker && FileMaker.PerformScript) {
						FileMaker.PerformScript(payload.script, payloadString);
					}
				} catch (e) {
					if (retry < 6) {
						//try six times: 3 seconds
						setTimeout(function () {
							fileMakerCall(payload, retry++);
						}, 500);
					} else {
						//reload the page and try again
						location.reload();
					}
				}
			} else if (getDBKPlatform() && getDBKPlatform() === 'dbkfmwd') {
				//calling from WebDirect
				window.parent.postMessage(JSON.stringify(payload), '*');
			}
		}

		function createURLFrame() {
			urlIframeElement = document.createElement('iframe');
			urlIframeElement.setAttribute('src', '');
			urlIframeElement.setAttribute('id', 'iframe');
			urlIframeElement.style.display = 'none';
			urlIframeElement.style.position = 'absolute';
			document.body.appendChild(urlIframeElement);

			return urlIframeElement;
		}

		function setURLFrame(url) {
			if (!urlIframeElement) {
				createURLFrame();
			}
			if (url) {
				if (!document.getElementById('iframe')) {
					createURLFrame();
				}
				urlIframeElement.setAttribute('src', url);
			}
		}

		function scriptURL(script, queryID) {
			//We use this to send a script request to filemaker. We spawm an iframe to prevent the page from reloading.
			if (!script) {
				return;
			}

			var queryIDString = queryID ? '&$queryID=' + queryID : '';

			//Write our queryID to the url hash. This way we can double check that the script was received in filemaker.
			$location.search('queryID', queryID);
			//window.location.hash = queryID;

			var url = urlParameters.filepath + '?' + script + queryIDString;
			if (environment.isIos) {
				// FM Go 15 doesn't work with the iFrame method so we set the url directly
				window.location.href = url;
			} else {
				//Setting an iframe seems to work better on windows as it allows the full 2056 characters. It also works in preview mode for printing on the mac.
				setURLFrame(url + queryIDString);
			}

			return url;
		}
		function getFile(file, callback, params, callbackSet) {
			//Remove element if it exists
			var headElement = document.getElementsByTagName('head')[0];
			var existingScriptElement = document.getElementById(file);
			if (existingScriptElement) {
				headElement.removeChild(existingScriptElement);
			}

			var callbackFunctionName = file.split('.')[0];

			if (!callbackSet) {
				crypt.setJSONPCallback(callbackFunctionName, function (data) {
					//Add back return characters that have been escaped
					data = data.replace(/\\n/g, '\n');
					callback(data, params);
				});
			}

			var tag = document.createElement('script');
			tag.src = file + '?v=' + new Date().getTime();
			tag.id = file;
			tag.onerror = function () {
				if (tag.onerror) {
					window.setTimeout(function () {
						getFile(file, callback, params, callbackSet);
					}, 50);
				}
			};

			document.getElementsByTagName('head')[0].appendChild(tag);
		}

		function getFileOnLoad(
			watcherID,
			watcherFile,
			dataFile,
			callback,
			params
		) {
			watcherIDNumber = watcherID;
			//We want to compare the current watch value against the last one recorded so if the value changed but it still isn't equal we don't loop forever waiting for a value that isn't coming.
			var lastWatchValue;
			if (!watcherID) {
				//If we don't have a watcherID there is nothing to watch so just get the file
				getFile(dataFile, callback, params);
			} else {
				var callbackFunctionName = watcherFile.split('.')[0];
				crypt.setJSONPCallback(callbackFunctionName, function (data) {
					watcherCallback(data);
				});
				//Call the watcher
				watcher();
			}
			//
			function watcherCallback(data) {
				if (watcherIDNumber !== watcherID) {
					callback();
					return;
				}

				var result = data ? data.split('|') : [];
				var queryIDResult = result[0];

				if (queryIDResult < watcherID || !lastWatchValue) {
					//If a new watcher id is found since we start looking we exit our loop essentially killing our watcher
					$timeout(function () {
						watcher();
					}, 50);
					lastWatchValue = queryIDResult;
				} else if (queryIDResult == watcherID) {
					if (result[1] == 'failed') {
						watcherIDNumber = null;
						//We are currently only refetching events if we detect a request failed. This could be expanded to other actions in the future.
						//Added timeout here because FileMaker might not be done with the previous script yet so we need to wait briefly to make sure FM is ready
						$timeout(function () {
							seedcodeCalendar
								.get('element')
								.fullCalendar('refetchEvents');
						}, 350);
					} else {
						//We can make the callback function unique if it's a watcher and then pass that unique id in the getFile function
						//Watcher has triggered on a match so let's get our events file
						getFile(dataFile, callback, params);
					}
				}
			}
			// //Define the watcher
			function watcher() {
				getFile(watcherFile, watcherCallback, null, true);
			}
		}

		// Helper function returns Custom Field ID by Store In Field Name
		function getCustomFieldIdByName(fieldName, schedule) {
			if (!schedule || !schedule.hasOwnProperty('customFieldMapLookup')) {
				return;
			}

			return fieldName in schedule.customFieldMapLookup
				? schedule.customFieldMapLookup[fieldName]
				: undefined;
		}

		/**
		 * Creates a tooltip using bootstrap.
		 * @constructor
		 * @param {(string|object)} targetElement - String target or elemnent where tooltip will attach.
		 * @param {string} content - The content inside the tooltip (html accepted).
		 * @param {string} className - Class to apply to parent element of tooltip.
		 * @param {string} placement - Tooltip placement: 'auto', 'top', 'right', 'bottom', 'left'.
		 * @param {string} container - Target element for containing element of tooltip.
		 * @param {string} viewport - Target element for the tooltip that wouldn't allow the element to escape.
		 * @param {number|object} delay - How many miliseconds until tooltip is shown once activated. Takes optional object { delay: 0, hide: 0 }
		 * @param {boolean} hideOnClick - If the tooltip should hide when the target element is clicked.
		 */
		function tooltip(
			targetElement,
			content,
			className,
			placement,
			container,
			viewport,
			delay,
			hideOnClick,
			options
		) {
			var classString = className
				? ' tooltip-custom ' + className
				: ' tooltip-custom';
			var uid = 'id-' + generateUID();
			var scrollContainer = $('.calendar-scroll');

			var removedUID;
			var tooltipElement;
			var createdElement;
			var targetElementId;

			delay =
				delay === undefined ||
				(typeof delay !== 'object' && !(delay >= 0))
					? 0
					: delay;

			// Delay can be passed as integer, or object containing delay and hide attributes

			if (options.delay === undefined) {
				delay = {show: 350, hide: 0};
			} else if (typeof options.delay === 'object') {
				delay = options.delay;
				delay.show = !delay.hasOwnProperty('show') ? 350 : delay.show;
				delay.hide = !delay.hasOwnProperty('hide') ? 0 : delay.hide;
			} else if (options.delay >= 0) {
				delay = {show: delay, hide: 0};
			}

			targetElement = $(targetElement);
			targetElementId = targetElement[0].dataset.id;
			container = container ? container : 'body';

			if (
				!viewport &&
				scrollContainer.length &&
				targetElement &&
				targetElement.length &&
				scrollContainer[0].contains(targetElement[0])
			) {
				viewport = scrollContainer;
			} else {
				viewport = !viewport ? $(container) : $(viewport);
			}

			// If new tooltip requested, run previously registered close functions
			if (
				tooltipManager.activeTooltipElements.hasOwnProperty(
					targetElementId
				)
			) {
				return;
			} else if (
				Object.keys(tooltipManager.activeTooltipElements).length > 0
			) {
				garbageCollectTooltips(targetElementId);
			}

			var viewportPosition = viewport.offset();
			var viewportHeight = viewport.height();
			var viewportWidth = viewport.width();
			var viewportScroll = viewport.scrollTop();
			var viewportBottom = viewportHeight + viewportScroll;

			var targetElementPosition = targetElement.offset();
			var targetElementHeight = targetElement.height();
			var targetElementWidth = targetElement.width();

			var fullHeightOffset = 50;

			var elementExistsTimer;
			var showTooltipTimer;
			var hide;

			var defaultHidden = options && options.hide ? options.hide : false;

			if (
				placement === 'auto' &&
				targetElementPosition.top <
					viewportPosition.top + fullHeightOffset &&
				viewportPosition.top +
					targetElementPosition.top +
					targetElementHeight >
					viewportPosition.top + viewportHeight - fullHeightOffset
			) {
				placement =
					targetElementPosition.left + targetElementWidth >
					viewportPosition.left + viewportWidth - viewportWidth / 2
						? 'left'
						: 'right';
			}

			var tooltipOptions = {
				container: container,
				title: content,
				animation: false,
				trigger: 'manual',
				html: true,
				placement: placement ? placement : 'top',
				viewport: viewport,
				postRenderFunction: options.postRenderFunction,
				postDestroyFunction: options.postDestroyFunction,
				template:
					'<div class="' +
					uid +
					' tooltip' +
					classString +
					'" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
			};

			if (!defaultHidden) {
				showTooltip(tooltipOptions);
			}

			function showTooltip(options) {
				tooltipElement = targetElement.tooltip({
					container: options.container,
					title: options.title,
					animation: options.animation,
					trigger: options.trigger,
					html: options.html,
					placement: options.placement,
					viewport: options.viewport,
					template: options.template,
				});

				tooltipElement.on('shown.bs.tooltip', function () {
					if (uid === removedUID) {
						hideTooltip(true);
						return;
					}
					createdElement = $('.' + uid);
					var tooltipArrowElement =
						createdElement.find('.tooltip-arrow');
					var createdElementPosition = createdElement.offset();
					var tooltipHeight = createdElement.height();

					var elementTop =
						viewportPosition.top < targetElementPosition.top
							? targetElementPosition.top
							: viewportPosition.top;
					var elementBottom =
						targetElementPosition.top + targetElementHeight <
						viewportPosition.top + viewportHeight
							? targetElementPosition.top + targetElementHeight
							: viewportPosition.top + viewportHeight;

					if (placement === 'left' || placement === 'right') {
						createdElement.css({
							top:
								elementTop +
								(elementBottom - elementTop) / 2 -
								tooltipHeight / 2 +
								'px',
						});
						tooltipArrowElement.css({top: '50%'});
					}

					// Save active tooltip
					tooltipManager.activeTooltipElements[targetElementId] = uid;
					tooltipManager.registeredCloseFunctions[uid] = hideTooltip;

					// If we have a hide delay, mouse over events prevent hiding
					createdElement.on('mouseenter', function () {
						tooltipManager.activeTooltipHoverFlag = true;
					});

					createdElement.on('mouseleave', function () {
						tooltipManager.activeTooltipHoverFlag = false;
						hideTooltip();
					});

					if (typeof options.postRenderFunction === 'function') {
						options.postRenderFunction();
					}
				});

				// Save tooltip we are requesting to open
				tooltipManager.tooltipOpenRequest = targetElementId;

				showTooltipTimer = window.setTimeout(function () {
					var lastOpenRequest = tooltipManager.tooltipOpenRequest;
					if (uid === removedUID) {
						hideTooltip(true);
					} else if (!hide && lastOpenRequest == targetElementId) {
						targetElement.tooltip('show');
						elementExists();
					}
				}, delay.show);
			}

			if (scrollContainer.length) {
				scrollContainer.on('scroll', hideTooltip);
			}

			targetElement.on('mouseleave', delayHide);

			if (hideOnClick) {
				targetElement.on('click', hideTooltip);
			}

			function delayHide() {
				tooltipManager.activeTooltipHoverFlag = false;
				setTimeout(hideTooltip, delay.hide);
			}

			function hideTooltip(force) {
				// If mouse is hovering over tooltip do not close.
				// Mouse out event will close

				if (
					!force &&
					(tooltipManager.activeTooltipHoverFlag == true ||
						tooltipManager.activeTooltipKeepAlive == true)
				) {
					return;
				}

				// If this tooltip has already been removed then nothing to do
				if (removedUID === uid && !force) {
					return;
				}

				delete tooltipManager.activeTooltipElements[targetElementId];
				delete tooltipManager.registeredCloseFunctions[uid];
				tooltipManager.activeTooltipHoverFlag = false;
				tooltipManager.activeTooltipKeepAlive = false;

				hide = true;
				window.clearTimeout(showTooltipTimer);
				window.clearTimeout(elementExistsTimer);

				if (scrollContainer.length) {
					scrollContainer.off('scroll', hideTooltip);
				}

				targetElement.off('mouseleave', delayHide);
				if (hideOnClick) {
					targetElement.off('click', hideTooltip);
				}
				targetElement.tooltip('destroy');

				// Set UID to removed so we don't hide again on something that has already been removed
				removedUID = uid;

				if (typeof options.postDestroyFunction === 'function') {
					options.postDestroyFunction();
				}
			}

			function removeElement(uid) {
				var selector = '.' + uid;
				var tooltipElement = document.querySelector(selector);
				if (tooltipElement) {
					tooltipElement.parentNode.removeChild(tooltipElement);
				}
			}

			function elementExists() {
				//Check wether the target element is still visible in the dom. We want to avoid showing the tooltip if the element is no longer visible
				elementExistsTimer = window.setTimeout(function () {
					var exists = document.body.contains(targetElement[0]);
					if (exists) {
						elementExists();
					} else {
						hideTooltip(true);
						//If the target element no longer exists we will most likely need to remove the popover from the dom manually
						removeElement(uid);
					}
				}, 500);
			}

			// Function toggles tooltip persistence while registering a container click handler that
			// garbage collects all open tooltips

			function setKeepAlive(keepAlive) {
				if (keepAlive) {
					scrollContainer.on('click', garbageCollectTooltips);
					tooltipManager.activeTooltipKeepAlive = true;
				} else {
					scrollContainer.off('click', garbageCollectTooltips);
					tooltipManager.activeTooltipKeepAlive = false;
				}
			}

			// Funciton garbage collects tooltips

			function garbageCollectTooltips(ignoreUID) {
				delete tooltipManager.activeTooltipHoverFlag;
				delete tooltipManager.activeTooltipKeepAlive;

				Object.keys(tooltipManager.registeredCloseFunctions).forEach(
					(tUID) => {
						if (tUID !== ignoreUID) {
							tooltipManager.registeredCloseFunctions[tUID](true);
						}
					}
				);

				// Add explicit garbage collection in case of race conditions
				// leaving orphans

				var orphans = document.getElementsByClassName('tooltip');
				if (orphans && orphans.length > -1) {
					for (let i = 0; i < orphans.length; i++) {
						orphans[i].parentNode.removeChild(orphans[i]);
					}
				}
			}

			return {
				// Function for show callback
				uid: uid,
				show: function (title) {
					if (title !== undefined) {
						tooltipOptions.title = title;
					}
					showTooltip(tooltipOptions);
				},
				hide: hideTooltip,
				setKeepAlive: setKeepAlive,
			};
		}

		/**
		 * Creates a mutation observer object
		 * @constructor
		 * @param {object} params - Object containing configuration for mutation observer.
		 */
		function observe(params) {
			// Each observer must be named
			if (!params.hasOwnProperty('name')) {
				showModal(
					'Observer Needs Name',
					'Please provide a name for your observer.',
					'ok'
				);
				return;
			}

			// If we have an observer registered by that name already, return this obserer
			if (mutationObservers.hasOwnProperty(params.name)) {
				return mutationObservers[params.name];
			}

			// Define object holding observer configuraiton and related functions
			var observerObj = {
				start: start,
				stop: stop,
				restart: restart,
				destroy: destroy,
				params: params,
			};

			// Declare what we're watching and what to do when we find it
			//
			//  watch:  element
			//  until:  conditionIsMet(observerObject) || "#querySelector .string"
			//  then:   performCustomCode(observerObject)

			observerObj.watch = params.hasOwnProperty('watch')
				? params.watch
				: undefined;
			observerObj.until = params.hasOwnProperty('until')
				? params.until
				: function () {
						return true;
					};
			observerObj.then = params.hasOwnProperty('then')
				? params.then
				: function () {
						return true;
					};

			// Observer Mutation Type Configuration Options. By default the observer will watch
			// the child list and all subtree changes. You do not need to modify these defaults
			// unless you have a special case where you don't need to monitor childList or
			// subtree changes. By default the stop condition will be checked when a
			// childList mutation is detected. Change this to "attribute" if you want to
			// react to attribute changes
			//
			// options:         { attributes: false, childList: true, subtree: true };
			// mutationType:    "childList" || "attribute"

			observerObj.options = params.hasOwnProperty('options')
				? params.options
				: {attributes: false, childList: true, subtree: true};

			// Process child list changes by default
			observerObj.mutationType = params.hasOwnProperty('mutationType')
				? params.mutationType
				: 'childList';

			// ----------------------------------------------------------------------------------------
			// Additional Stop/Start/Break Options
			// ----------------------------------------------------------------------------------------

			// Check stop condition when observer first starts - Default true
			observerObj.checkStopConditionOnStart = params.hasOwnProperty(
				'checkStopConditionOnStart'
			)
				? params.checkStopConditionOnStart
				: true;

			// Stop observing further mutations when first matching mutation is found - Default false
			observerObj.whenFoundStopObserving = params.hasOwnProperty(
				'whenFoundStopObserving'
			)
				? params.whenFoundStopObserving
				: false;

			// Stop processing remaining list of mutions when first instance is found - Default true
			observerObj.whenFoundStopProcessing = params.hasOwnProperty(
				'whenFoundStopProcessing'
			)
				? params.whenFoundStopProcessing
				: true;

			// Stop processing remaining list of mutions when first instance is found - Default true
			observerObj.debug = params.hasOwnProperty('debug')
				? params.debug
				: false;

			// Auto-start is true by default
			observerObj.autoStart = params.hasOwnProperty('autoStart')
				? params.autoStart
				: true;

			// Start delay
			observerObj.startDelay = params.hasOwnProperty('startDelay')
				? params.startDelay
				: 0;

			// ------------------ End Configuration ------------------

			// Define callback function for observer
			observerObj.callback = function (mutationsList, observer) {
				observerObj.mutationList = mutationsList;
				for (const mutation of mutationsList) {
					if (
						observerObj.mutationType == 'both' ||
						mutation.type === observerObj.mutationType
					) {
						observerObj.lastMutation = mutation;

						if (
							typeof observerObj.until !== 'function' &&
							document.querySelector(observerObj.until) !==
								null &&
							observerObj.running
						) {
							observerObj.foundNode = document.querySelector(
								observerObj.until
							);

							if (observerObj.whenFoundStopObserving) stop();
							if (observerObj.debug)
								console.log(
									'O: Mutation Triggering Custom Code'
								);
							observerObj.then(observerObj);
							if (observerObj.whenFoundStopProcessing) break;
						} else if (
							typeof observerObj.until === 'function' &&
							observerObj.until(observerObj) &&
							observerObj.running
						) {
							if (observerObj.whenFoundStopObserving) stop();
							if (observerObj.debug)
								console.log(
									'O: Mutation Triggering Custom Code'
								);
							observerObj.then(observerObj);
							if (observerObj.whenFoundStopProcessing) break;
						}
					}
				}
			};

			observerObj.running = false;

			mutationObservers[params.name] = observerObj;

			if (observerObj.autoStart) {
				if (observerObj.startDelay > 0) {
					setTimeout(start, observerObj.startDelay);
				} else {
					start();
				}
			}

			return mutationObservers[params.name];

			// ------------------ Observer Functions ------------------

			function start() {
				resolveWatch();

				if (
					typeof observerObj.watch !== 'string' &&
					observerObj.watch &&
					observerObj.watch !== undefined
				) {
					if (observerObj.checkStopConditionOnStart) {
						if (observerObj.debug)
							console.log('O: Check Condition on Stat');

						observerObj.checkStopConditionOnStart = false;

						if (
							typeof observerObj.until !== 'function' &&
							document.querySelector(observerObj.until) !== null
						) {
							observerObj.foundNode = document.querySelector(
								observerObj.until
							);
							observerObj.foundOnStart = true;
							observerObj.then(observerObj);
						} else if (
							typeof observerObj.until === 'function' &&
							observerObj.until(observerObj)
						) {
							observerObj.foundOnStart = true;
							observerObj.then(observerObj);
						}
					}

					if (observerObj.hasOwnProperty('observer')) {
						return;
					}

					if (observerObj.foundOnStart) {
						if (observerObj.debug)
							console.log(
								'O: Found Condition on Start. No need to observe.'
							);

						return;
					}

					if (observerObj.debug)
						console.log('O: Creating New MutationObserver Object');

					observerObj.observer = new MutationObserver(
						observerObj.callback
					);
					observerObj.observer.observe(
						observerObj.watch,
						observerObj.options
					);
					observerObj.running = true;
				}
			}

			function restart() {
				if (observerObj.debug) console.log('O: Restarting Observer');
				observerObj.foundOnStart = false;
				observerObj.lastMutation = undefined;
				observerObj.observer = new MutationObserver(
					observerObj.callback
				);
				observerObj.observer.observe(
					observerObj.watch,
					observerObj.options
				);
				observerObj.running = true;
			}

			function destroy() {
				if (
					observerObj.hasOwnProperty('observer') &&
					observerObj.observer !== undefined
				) {
					observerObj.observer.disconnect();
					observerObj.running = false;
				}
				delete mutationObservers[observerObj.params.name];
			}

			function stop() {
				if (
					observerObj.hasOwnProperty('observer') &&
					observerObj.observer !== undefined
				) {
					observerObj.observer.disconnect();
					observerObj.running = false;
				}
				if (observerObj.debug) console.log('O: Stopped Observer');
			}

			function resolveWatch() {
				if (typeof observerObj.watch === 'string') {
					let watch = document.querySelector(observerObj.watch);
					if (watch) {
						observerObj.watch = watch;
						restart();
					} else {
						observerObj.definedWatch = observerObj.watch;
						observerObj.watch = document;
						observerObj.definedUntil = observerObj.until;
						observerObj.until = observerObj.definedWatch;
						observerObj.definedThen = observerObj.then;
						observerObj.then = function () {
							observerObj.watch = observerObj.definedWatch;
							observerObj.until = observerObj.definedUntil;
							observerObj.then = observerObj.definedThen;
							resolveWatch();
						};
					}
				}
			}
		}

		function popover(config, content) {
			var template;
			var popoverController;
			var inputs;
			var dataTitle = config.dataTitle ? config.dataTitle : 'data';
			var controller = config.controller;
			var popoverScope = $rootScope.$new();
			var classString = config.class
				? ' class="' + config.class + '"'
				: '';
			var styleString = config.runFunction
				? ' style="cursor: pointer;"'
				: '';
			var scopeCallback = config.scopeCallback;
			if (!$rootScope.modal) {
				$rootScope.modal = {watch: 1};
			} else {
				$rootScope.modal.watch++;
			}
			config.watch = $rootScope.modal.watch;
			config.id = config.id || config.type;
			$rootScope.modal.id = config.id || config.type;

			popoverScope.popover = {
				config: config,
			};

			$rootScope.modal.popover = popoverScope;

			//If a controller was passed in then let's assign that
			if (controller) {
				popoverScope[dataTitle] = config.data;
				inputs = {
					$scope: popoverScope,
				};
				popoverController = $controller(controller, inputs);
			}

			if (config.type === 'message') {
				template =
					'<message-dialog content="' +
					dataTitle +
					'" show="popover.config.show" config="popover.config"' +
					classString +
					styleString +
					'>\n' +
					content +
					'\n' +
					'</message-dialog>\n';
			} else if (config.type === 'non-modal') {
				template =
					'<non-modal-dialog content="' +
					dataTitle +
					'" show="popover.config.show" config="popover.config">\n' +
					content +
					'\n' +
					'</non-modal-dialog>\n';
			} else if (config.type === 'modal') {
				template =
					'<modal-dialog content="' +
					dataTitle +
					'" show="popover.config.show" config="popover.config">\n' +
					content +
					'\n' +
					'</modal-dialog>\n';
			} else if (config.type === 'mobile') {
				template =
					'<mobile-modal content="' +
					dataTitle +
					'" show="popover.config.show" config="popover.config">\n' +
					content +
					'\n' +
					'</mobile-modal>\n';
			} else if (config.type === 'info') {
				template =
					'<calendar-info content="' +
					dataTitle +
					'" show="popover.config.show" config="popover.config">\n' +
					content +
					'\n' +
					'</calendar-info>\n';
			} else {
				template =
					'<popover content="' +
					dataTitle +
					'" show="popover.config.show" config="popover.config">\n' +
					content +
					'\n' +
					'</popover>\n';
			}

			var compiledContent = $compile(template)(popoverScope);
			//Append the newly created html element. Wrap in an evalAsync so we start a digest cycle
			popoverScope.$evalAsync(function () {
				angular.element(config.container).append(compiledContent);
				//Run a scope callback if one exists. This takes a string and runs that as a scope function for example 'close' runs as $scope.close(); once the scope is loaded
				if (scopeCallback && popoverScope[scopeCallback]) {
					popoverScope[scopeCallback]();
				}
			});
			// Return object of methods and popover element
			return {element: compiledContent, destroy: destroy};

			function destroy() {
				popoverScope.$evalAsync(() => {
					config.show = false;
				});
			}
		}

		function showMessage(
			content,
			showDelay,
			hideDelay,
			type,
			actionFunction,
			logError
		) {
			//Hide any messages that might currently be up
			hideMessages();

			// Log error with system health
			if (logError) {
				if (showDelay) {
					showDelay += 700;
				} else {
					showDelay = 700;
				}
				configErrorToSystemHealth(content);
			}

			//Show the new message
			var messageClass = type ? 'message-' + type : '';
			var additionalTemplate =
				type === 'error'
					? '<span class="message-icon"><i class="fa fa-question-circle fa-lg dbk_icon_help" style="display: inline-block; vertical-align: middle;"></i></span>'
					: '';
			var template =
				'<div class="message-text">' +
				content +
				additionalTemplate +
				'</div>';
			var container = '#calendar-container';

			if (type === 'error' && !actionFunction) {
				actionFunction = errorFunction;
			}

			if (type === 'full-width') {
				container = 'body';
			}

			var message = {
				container: container,
				type: 'message', // modal, popover or message
				destroy: true,
				delay: hideDelay,
				runFunction: actionFunction ? runFunction : null,
				class: messageClass,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				show: true,
			};

			$timeout(
				function () {
					popover(message, template);
				},
				Math.max(0, showDelay)
			);

			function runFunction($event) {
				if (actionFunction) {
					message.show = false;
					actionFunction();
				}
			}

			//Define a generic error function that will run when the message is clicked if another function isn't specified
			function errorFunction() {
				help('Troubleshooting', '76-troubleshooting');
			}
		}

		//Hide any messages being displayed
		function hideMessages(type) {
			if (!type) {
				type = 'message';
			}
			if (!$rootScope.modal) {
				$rootScope.modal = {watch: 0};
			}

			$timeout(function () {
				$rootScope.modal.watch++;
				$rootScope.modal.id = type;
			});
		}

		function showModal(
			title,
			content,
			cancelButtonText,
			cancelFunction,
			confirmButtonText,
			confirmFunction,
			secondaryButtonText,
			secondaryFunction,
			warning,
			modalContainer,
			htmlDescription
		) {
			var confirmButtonStyle = warning
				? 'btn-danger dbk_button_danger'
				: 'btn-success dbk_button_success';
			var cancelButton = cancelButtonText
				? '<button translate ng-click="popover.config.cancelFunction();" class="btn btn-xs btn-secondary">' +
					cancelButtonText +
					'</button>'
				: '';
			var confirmButton = confirmButtonText
				? '<button translate ng-click="popover.config.confirmFunction();" class="btn btn-xs  ' +
					confirmButtonStyle +
					'">' +
					confirmButtonText +
					'</button>'
				: '';
			var secondaryButton = secondaryButtonText
				? '<button translate ng-click="popover.config.secondaryFunction();" class="btn btn-xs btn-success dbk_button_success">' +
					secondaryButtonText +
					'</button>'
				: '';

			var template =
				'<div style="background: rgba(0,0,0,0.75); color: white;">' +
				//add a little margin to the top of the buttons (repeating-dialog), if there's three in case they stack (especially in french, etc.)
				(secondaryButtonText
					? '<div class="pad-large text-center repeating-dialog">'
					: '<div class="pad-large text-center">') +
				'<h4 translate>' +
				title +
				'</h4>' +
				(htmlDescription
					? '<p>' + content + '</p>'
					: '<p translate>' + content + '</p>') +
				'<div class="pad">' +
				(secondaryButtonText
					? cancelButton +
						'&nbsp;&nbsp;' +
						confirmButton +
						' ' +
						secondaryButton
					: cancelButton + ' ' + confirmButton) +
				'</div>' +
				'</div>' +
				'</div>';

			var config = {
				container: modalContainer
					? modalContainer
					: document.querySelector('#calendar-container')
						? '#calendar-container'
						: '#app-container',
				type: 'modal', // modal or popover
				controller: 'ModalCtrl',
				destroy: true,
				confirmFunction: runConfirmFunction,
				cancelFunction: runCancelFunction,
				secondaryFunction: runSecondaryFunction,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				show: true,
			};

			popover(config, template);

			// Functions can return an object containging a show property to keep modal open
			function runConfirmFunction() {
				var result;
				if (confirmFunction) {
					result = confirmFunction();
				}
				config.show = result && result.show ? true : false;
			}

			function runSecondaryFunction() {
				var result;
				if (secondaryFunction) {
					result = secondaryFunction();
				}
				config.show = result && result.show ? true : false;
			}

			function runCancelFunction() {
				var result;
				if (cancelFunction) {
					result = cancelFunction();
				}
				config.show = result && result.show ? true : false;
			}
		}

		function updateConfig(configItem, value) {
			var element = seedcodeCalendar.get('element');
			var config = seedcodeCalendar.get('config');

			if (!element || !config) {
				return;
			}

			config[configItem] = value;
			element.fullCalendar('updateConfig', configItem, value);
		}

		function loadScript(file, headElement) {
			var fileID = stringToID(file);
			var fileExists = document.getElementById(fileID);

			if (fileExists) {
				return;
			}
			var head = headElement
				? headElement
				: document.getElementsByTagName('head')[0];
			var script = document.createElement('script');
			script.src = file + '?v={build-data::cacheBust}';
			script.id = fileID;
			head.appendChild(script);
		}

		function getTimezones() {
			var config = seedcodeCalendar.get('config');
			var momentTimezones = moment.tz.names();
			var timezoneList = [];
			var timezone;
			var timezoneOverrideList = [];
			var timezoneOverrideMatrix = {};

			if (config.timezonesAvailable) {
				timezoneOverrideList = config.timezonesAvailable.split(',');
			}

			for (var i = 0; i < timezoneOverrideList.length; i++) {
				timezoneOverrideList[i] = timezoneOverrideList[i].trim();
				timezoneOverrideMatrix[timezoneOverrideList[i]] =
					timezoneOverrideList[i];
			}

			for (var i = 0; i < momentTimezones.length; i++) {
				timezone = {
					value: momentTimezones[i],
					title: momentTimezones[i],
					// group: momentTimezones[i] ? momentTimezones[i].split('/')[0] : '',
				};

				if (timezoneOverrideList && timezoneOverrideList.length) {
					if (timezoneOverrideMatrix[momentTimezones[i]]) {
						timezoneList.push(timezone);
					}
				} else {
					timezoneList.push(timezone);
				}
			}

			timezoneList.unshift({value: '', title: 'Auto'});

			return timezoneList;
		}

		function getLocalTimeFormat() {
			var year = '1999';
			var month = '1';
			var day = '18';
			var hour = '13';
			var minute = '45';

			var timeString = new Date(
				year,
				month,
				day,
				hour,
				minute,
				0
			).toLocaleTimeString();

			var timeArray = timeString.split(':');
			var timeFormat = timeArray[0] === hour ? 'H:mm' : 'h:mm a'; //Space used here is a hair space &#8202;

			return timeFormat;
		}

		function generateTimeList(timeFormat) {
			var showMeridian = timeFormat.indexOf('a') > -1;
			var output = [
				{
					title: '',
					value: '',
					meridian: '',
				},
			];
			var today = moment().hours(0).minutes(0).seconds(0).milliseconds(0);
			var date;
			var time = {};

			for (var i = 0; i < 25; i++) {
				time = {};
				date = today.clone().hours(i);

				if (i === 24) {
					time.value = '24:00:00';
				} else {
					time.value = date.format('HH:mm:ss');
				}
				time.title = date.format(timeFormat);

				if (showMeridian) {
					time.meridian = i > 11 ? 'PM' : 'AM';
				} else {
					time.meridian = '';
				}
				output.push(time);
			}
			return output;
		}

		function hexToRgb(hex) {
			var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
			return result
				? {
						r: parseInt(result[1], 16),
						g: parseInt(result[2], 16),
						b: parseInt(result[3], 16),
					}
				: null;
		}

		function componentToHex(c) {
			var hex = c.toString(16);
			return hex.length == 1 ? '0' + hex : hex;
		}

		function rgbToHex(rgbString) {
			var rgbArray = rgbString.split(',');
			for (var i = 0; i < 3; i++) {
				rgbArray[i] = parseInt(rgbArray[i].match(/\d+/g), 10);
			}

			return (
				'#' +
				componentToHex(rgbArray[0]) +
				componentToHex(rgbArray[1]) +
				componentToHex(rgbArray[2])
			);
		}

		function generateRandomColor(previousColor) {
			var ranges = {
				red: {
					red1: 171,
					red2: 218,
					green1: 92,
					green2: 136,
					blue1: 109,
					blue2: 166,
				},
				purple: {
					red1: 155,
					red2: 202,
					green1: 92,
					green2: 130,
					blue1: 139,
					blue2: 185,
				},
				violet: {
					red1: 152,
					red2: 202,
					green1: 97,
					green2: 138,
					blue1: 162,
					blue2: 216,
				},
				darkPink: {
					red1: 184,
					red2: 217,
					green1: 128,
					green2: 173,
					blue1: 173,
					blue2: 208,
				},
				pink: {
					red1: 215,
					red2: 231,
					green1: 164,
					green2: 205,
					blue1: 191,
					blue2: 224,
				},
				grey: {
					red1: 143,
					red2: 192,
					green1: 159,
					green2: 204,
					blue1: 179,
					blue2: 220,
				},
				blue: {
					red1: 82,
					red2: 135,
					green1: 156,
					green2: 192,
					blue1: 161,
					blue2: 201,
				},
				green: {
					red1: 129,
					red2: 173,
					green1: 165,
					green2: 203,
					blue1: 100,
					blue2: 150,
				},
				yellow: {
					red1: 173,
					red2: 225,
					green1: 203,
					green2: 218,
					blue1: 150,
					blue2: 97,
				},
			};

			return returnRGB(pickObject());

			function pickObject() {
				var choice;
				var colorObject;
				var keys = Object.keys(ranges);
				choice = Math.ceil(Math.random() * 9);
				colorObject = ranges[keys[choice - 1]];
				return colorObject;
			}

			function returnRGB(colorObject) {
				var r = valueFromRange(colorObject.red1, colorObject.red2);
				var g = valueFromRange(colorObject.green1, colorObject.green2);
				var b = valueFromRange(colorObject.blue1, colorObject.blue2);
				return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + '0.85' + ')';
			}

			function valueFromRange(value1, value2) {
				var highValue = Math.max(value1, value2);
				var lowValue = Math.min(value1, value2);
				var dif = (highValue - lowValue) * Math.random();
				return Math.floor(dif) + lowValue;
			}
		}

		function generateTextColor(backgroundColor) {
			const config = seedcodeCalendar.get('config');
			let textColor = config.defaultTextColor;
			if (!backgroundColor) {
				return textColor;
			}
			const isHex = backgroundColor.indexOf('#') > -1;

			//Detect RGB values to see if color is dark enough to invert text color
			let colorValues;
			if (isHex) {
				const rgbValuesFromHex = hexToRgb(backgroundColor);
				if (!rgbValuesFromHex) {
					return textColor;
				}
				colorValues = [
					rgbValuesFromHex.r,
					rgbValuesFromHex.g,
					rgbValuesFromHex.b,
				];
			} else {
				colorValues = backgroundColor.split(',');
			}

			for (let i = 0; i < 3; i++) {
				if (parseInt(colorValues[i], 10) < 100) {
					textColor = config.defaultLightTextColor;
				}
			}
			return textColor;
		}

		function generateColorTransparency(backgroundColor, opacity) {
			var isHex = backgroundColor.indexOf('#') > -1;
			var rgbValues = [];
			var contentString;
			//Detect RGB values to see if color is dark enough to invert text color
			var colorValues = isHex
				? [
						hexToRgb(backgroundColor).r.toString(),
						hexToRgb(backgroundColor).g.toString(),
						hexToRgb(backgroundColor).b.toString(),
					]
				: backgroundColor.split(',');
			for (var i = 0; i < 3; i++) {
				rgbValues.push(colorValues[i].match(/\d+/g));
			}
			contentString = rgbValues.join(',');
			return 'rgba(' + contentString + ',' + opacity + ')';
		}

		//Function to scroll an element on the y axis providing smooth animation
		function scrollToY(
			scrollElement,
			scrollTargetY,
			speed,
			easing,
			callback
		) {
			// scrollElement: the target element to be scrolled - native javascript element
			// scrollTargetY: the target scrollY position
			// speed: time in pixels per second
			// easing: easing equation to use
			// callback: callback function to run when done scrolling

			var scrollY = scrollElement.scrollTop,
				scrollTargetY = scrollTargetY || 0,
				speed = speed || 2000,
				easing = easing || 'easeOutSine',
				currentTime = 0;

			// min time .1, max time .8 seconds
			var time = Math.max(
				0.1,
				Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8)
			);

			// easing equations from https://github.com/danro/easing-js/blob/master/easing.js
			var PI_D2 = Math.PI / 2,
				easingEquations = {
					easeOutSine: function (pos) {
						return Math.sin(pos * (Math.PI / 2));
					},
					easeInOutSine: function (pos) {
						return -0.5 * (Math.cos(Math.PI * pos) - 1);
					},
					easeInOutQuint: function (pos) {
						if ((pos /= 0.5) < 1) {
							return 0.5 * Math.pow(pos, 5);
						}
						return 0.5 * (Math.pow(pos - 2, 5) + 2);
					},
				};

			// add animation loop
			function tick() {
				currentTime += 1 / 60;

				var p = currentTime / time;
				var t = easingEquations[easing](p);

				if (p < 1) {
					requestAnimFrame(tick);

					scrollElement.scrollTop =
						scrollY + (scrollTargetY - scrollY) * t;
				} else {
					scrollElement.scrollTop = scrollTargetY;
					if (callback) {
						callback();
					}
				}
			}
			// call it once to get started
			tick();
		}

		//Function to scroll an element on the x axis providing smooth animation
		function scrollToX(
			scrollElement,
			scrollTargetX,
			speed,
			easing,
			callback
		) {
			// scrollElement: the target element to be scrolled - native javascript element
			// scrollTargetY: the target scrollY position
			// speed: time in pixels per second
			// easing: easing equation to use
			// callback: callback function to run when done scrolling

			var scrollX = scrollElement.scrollLeft,
				scrollTargetX = scrollTargetX || 0,
				speed = speed || 2000,
				easing = easing || 'easeOutSine',
				currentTime = 0;

			// min time .1, max time .8 seconds
			var time = Math.max(
				0.1,
				Math.min(Math.abs(scrollX - scrollTargetX) / speed, 0.8)
			);

			// easing equations from https://github.com/danro/easing-js/blob/master/easing.js
			var PI_D2 = Math.PI / 2,
				easingEquations = {
					easeOutSine: function (pos) {
						return Math.sin(pos * (Math.PI / 2));
					},
					easeInOutSine: function (pos) {
						return -0.5 * (Math.cos(Math.PI * pos) - 1);
					},
					easeInOutQuint: function (pos) {
						if ((pos /= 0.5) < 1) {
							return 0.5 * Math.pow(pos, 5);
						}
						return 0.5 * (Math.pow(pos - 2, 5) + 2);
					},
				};

			// add animation loop
			function tick() {
				currentTime += 1 / 60;

				var p = currentTime / time;
				var t = easingEquations[easing](p);

				if (p < 1) {
					requestAnimFrame(tick);

					scrollElement.scrollLeft =
						scrollX + (scrollTargetX - scrollX) * t;
				} else {
					scrollElement.scrollLeft = scrollTargetX;
					if (callback) {
						callback();
					}
				}
			}
			// call it once to get started
			tick();
		}

		function floatMath(number1, number2) {
			var numbers = [number1, number2];
			var decimalCharacter = '.';

			var decimalPlaces = 0;
			var numberSplit;
			var multiplier = '';
			var result = 0;

			var i;

			for (i = 0; i < numbers.length; i++) {
				numberSplit = numbers[i].toString().split(decimalCharacter);
				if (
					numberSplit &&
					numberSplit[1] &&
					numberSplit[1] > decimalPlaces
				) {
					decimalPlaces = numberSplit[1].length;
				}
			}

			for (i = 0; i < decimalPlaces; i++) {
				multiplier += '0';
			}

			multiplier = Number('1' + result);

			for (i = 0; i < numbers.length; i++) {
				result += numbers[i] * multiplier;
			}

			return Number((result / multiplier).toFixed(10));
		}

		function eventTitleCalc(value, event, schedule) {
			value = value + ''; //Coerce to a string;

			var customElement = 'dbk-css'; //The custom element that we use to identify custom styles <dbk-css></dbk-css>

			var subWrapper = '!~!';
			var cssSubToken = 'CSSSUB';
			var cssSubEndToken = 'CSSSUBEND';

			//Get all matching elements
			var matchingElementsRegex = new RegExp(
				'<' + customElement + '\\s+.*?>',
				'gi'
			);
			var matchingElements = value.match(matchingElementsRegex);

			var endElementRegex;
			var endSubRegex;
			var i;

			var fieldString = value;

			// Remove return characters from the template
			value = value.split('\n').join('');

			if (matchingElements && matchingElements.length) {
				endElementRegex = new RegExp('</' + customElement + '>', 'gi');
				endSubRegex = new RegExp(
					subWrapper + cssSubEndToken + subWrapper,
					'gi'
				);

				//Loop through matching elements and substitute for temporary tokens
				for (i = 0; i < matchingElements.length; i++) {
					value = value.replace(
						matchingElements[i],
						subWrapper + cssSubToken + i + subWrapper
					);
					fieldString = fieldString.replace(matchingElements[i], '');
				}
				value = value.replace(
					endElementRegex,
					subWrapper + cssSubEndToken + subWrapper
				);
				fieldString = fieldString.replace(endElementRegex, '');

				value = parseFields(
					value,
					event,
					schedule,
					fieldString,
					matchingElements
				);

				//Loop through matching spans again and replace temp tokens with scrubbed span elements
				for (i = 0; i < matchingElements.length; i++) {
					value = value.replace(
						subWrapper + cssSubToken + i + subWrapper,
						matchingElements[i]
					);
				}
				return value.replace(endSubRegex, '</' + customElement + '>');
			} else {
				return parseFields(value, event, schedule);
			}

			function parseFields(
				value,
				event,
				schedule,
				fieldString,
				dbkCSSElements
			) {
				var commaReplace;
				var fieldValueFound;
				var subWrapper = '!~!';
				var commaSubToken = 'COMMASUB';
				var fieldErrorMessage = 'is not a field';

				var customFields = schedule.customFields
					? schedule.customFields
					: {};
				var customFieldMapLookup = schedule.customFieldMapLookup
					? schedule.customFieldMapLookup
					: {};

				var fieldMapLookup = schedule.fieldMapLookup
					? schedule.fieldMapLookup
					: {};

				var matchingElementsRegex = new RegExp(',', 'g');
				var matchingElements = value.match(matchingElementsRegex);

				var fields = fieldString
					? fieldString.split(',')
					: value.split(',');

				// Substitute all commas in our value so we know which comma matches which field
				if (matchingElements) {
					for (i = 0; i < matchingElements.length; i++) {
						value = value.replace(
							matchingElements[i],
							subWrapper + commaSubToken + (i + 1) + subWrapper
						);
					}
				}

				for (var i = 0; i < fields.length; i++) {
					let field = fields[i].trim();
					let fieldValue = getFieldValue(
						field,
						customFields,
						fieldErrorMessage
					);
					fieldValue =
						fieldValue === undefined || fieldValue === null
							? ''
							: fieldValue;

					let fieldIsEmpty = eventFieldIsEmpty(fieldValue);

					if (fieldValueFound && !fieldIsEmpty) {
						commaReplace = '\n';
					} else {
						commaReplace = '';
					}

					if (!fieldIsEmpty) {
						fieldValueFound = true;
					}

					value = value
						.split(field)
						.join(fieldValue)
						.replace(
							subWrapper + commaSubToken + i + subWrapper,
							commaReplace
						);
				}

				// Replace any class definitions matching field names
				if (dbkCSSElements) {
					for (i = 0; i < dbkCSSElements.length; i++) {
						// Get the class name
						let className =
							dbkCSSElements[i].match(/class="([^"]*)"/)?.[1];
						if (className) {
							// Get the field value
							let fieldValue = getFieldValue(
								className,
								customFields
							);

							if (fieldValue) {
								// Replace the class name with the field value
								dbkCSSElements[i] = dbkCSSElements[i].replace(
									new RegExp(
										'class="' + className + '"',
										'g'
									),
									'class="' + fieldValue + '"'
								);
							}
						}
					}
				}

				return value;

				function getFieldValue(field, customFields, fieldErrorMessage) {
					var fieldName;
					var fieldValue;
					var isCustomField;

					var fieldLower = field.toLowerCase();

					if (fieldLower === 'title' || fieldLower === 'titleedit') {
						fieldLower = 'titleEdit';
					}

					if (customFields.hasOwnProperty(field)) {
						fieldValue = event[field];
						fieldName = customFields[field];
						isCustomField = true;
					} else if (fieldMapLookup.hasOwnProperty(field)) {
						fieldName = fieldMapLookup[field];
					} else if (event.hasOwnProperty(fieldLower)) {
						fieldName = fieldLower;
					} else if (customFieldMapLookup.hasOwnProperty(field)) {
						fieldValue = event[customFieldMapLookup[field]];
						fieldName = customFields[customFieldMapLookup[field]];
						isCustomField = true;
					}

					if (fieldName) {
						return isCustomField
							? formatCustomFieldData(fieldValue, fieldName)
							: formatFieldData(
									fieldValue || event[fieldName],
									fieldName,
									event
								);
					} else {
						return fieldErrorMessage
							? '<dbk-css style="color: red;">"' +
									field +
									'" ' +
									fieldErrorMessage +
									'</dbk-css>'
							: null;
					}
				}
			}
		}

		function eventFieldIsEmpty(value) {
			if (Array.isArray(value)) {
				if (!value.length || value.join('') === '') {
					return true;
				}
			} else {
				return value === '' || value === undefined;
			}
		}

		function formatFieldData(value, field, event) {
			var config = seedcodeCalendar.get('config');
			var formattedValue;

			if (field === 'start' || field === 'end') {
				if (!value) {
					foramttedValue = '';
				}
				if (event.allDay) {
					formattedValue = moment(value).format(
						config.dateStringShortFormat
					);
				} else {
					if (event.schedule.sourceTypeID === 9) {
						formattedValue = moment(value).format(
							config.dateStringShortFormat +
								' ' +
								config.timeFormat
						);
					} else {
						formattedValue = $.fullCalendar
							.createTimezoneTime(moment(value), false, true)
							.format(
								config.dateStringShortFormat +
									' ' +
									config.timeFormat
							);
					}
				}
			} else if (Array.isArray(value)) {
				formattedValue = value.join(', ');
			} else {
				formattedValue = value;
			}

			return formattedValue;
		}

		function formatCustomFieldData(value, customField) {
			var config = seedcodeCalendar.get('config');
			var formattedValue;

			if (
				customField.formatas === 'number' ||
				customField.formatas === 'currency' ||
				customField.formatas === 'percent'
			) {
				formattedValue = formatDisplayNumber(
					value,
					customField.formatas,
					null,
					customField,
					customField.precision,
					false,
					true
				);
			} else if (customField.formatas === 'checkbox') {
				formattedValue =
					value === true || value === 'true' ? customField.name : '';
			} else if (customField.formatas === 'select') {
				try {
					formattedValue = value ? JSON.parse(value).join(', ') : '';
				} catch (error) {
					formattedValue = value || '';
				}
			} else if (customField.formatas === 'date') {
				formattedValue = moment(value).isValid()
					? moment(value).format(config.dateStringShortFormat)
					: '';
			} else if (customField.formatas === 'timestamp') {
				formattedValue = moment(value).isValid()
					? moment(value).format(
							config.dateStringShortFormat +
								' ' +
								config.timeFormat
						)
					: '';
			} else if (customField.formatas === 'url') {
				formattedValue = value
					? '<a href="' +
						value +
						'" target="_blank">' +
						value +
						'</a>'
					: '';
			} else {
				formattedValue = value;
			}

			return formattedValue;
		}

		function formatDisplayNumber(
			numberInput,
			type,
			dataType,
			format,
			precision,
			preventRounding,
			noStyles
		) {
			var thousandsSeparator = format.thousandsSeparator;
			var decimalCharacter = format.decimalCharacter || '.';
			var decimalPlaces = format.decimalPlaces;

			var numberLabel = format.numberLabel;
			var numberLabelPosition = format.numberLabelPosition;

			var numberLabelBefore =
				numberLabel && numberLabelPosition === 'before'
					? numberLabel
					: format.numberLabelBefore;
			var numberLabelAfter =
				numberLabel && numberLabelPosition === 'after'
					? numberLabel
					: format.numberLabelAfter;

			var negativeCharacter = '-';

			var preNumberLabelAfter = '';

			var numberLabelBeforeClasses;
			var numberLabelAfterClasses;

			var negativeValue;
			var integerValue;
			var decimalValue;
			var formattedNumber;
			var number;
			var numberString;

			var translations = $translate.instant([
				'item',
				'items',
				'hour',
				'hours',
			]);

			precision = precision ? precision : decimalPlaces;

			if (dataType === 'count') {
				numberLabelBefore = '';
				numberLabelAfter =
					numberInput === 1
						? ' ' + translations['item']
						: ' ' + translations['items'];
				type = 'number';
			} else if (dataType === 'duration') {
				numberLabelBefore = '';
				numberLabelAfter =
					numberInput === 1
						? ' ' + translations['hour']
						: ' ' + translations['hours'];
				type = 'number';
			}

			if (noStyles) {
				numberLabelBefore = numberLabelBefore ? numberLabelBefore : '';
				numberLabelAfter = numberLabelAfter ? numberLabelAfter : '';
			} else {
				numberLabelBeforeClasses =
					numberLabelBefore && numberLabelBefore.length > 1
						? 'label-before-value label-long'
						: 'label-before-value label-short';
				numberLabelAfterClasses =
					numberLabelAfter && numberLabelAfter.length > 1
						? 'label-after-value label-long'
						: 'label-after-value label-short';
				numberLabelBefore = numberLabelBefore
					? '<span class="' +
						numberLabelBeforeClasses +
						'">' +
						numberLabelBefore +
						'</span>'
					: '';
				numberLabelAfter = numberLabelAfter
					? '<span class="' +
						numberLabelAfterClasses +
						'">' +
						numberLabelAfter +
						'</span>'
					: '';
			}

			if (!numberInput && numberInput !== 0) {
				numberInput = '';
			}
			if (type === 'percent' && !noStyles) {
				// Used for formatting analytics percent
				numberInput = numberInput * 100;
				preNumberLabelAfter = '%';
			}

			//Build regex to replace symbols that we will add back later
			var replaceSymbolRegexString =
				'[' + escapeRegExp(thousandsSeparator) + ']';
			var replaceSymbolRegexp = new RegExp(replaceSymbolRegexString, 'g');
			// var regexString = "(" + negativeCharacter + ")?(\\d+)" + "(\\" + decimalCharacter + "\\d{0," + decimalPlaces + "})?"; ///(-?\d+)(\.\d{0,2})?/

			//Build regex to extract the first number we find in the input
			var matchNumberString =
				'(' +
				escapeRegExp(negativeCharacter) +
				')?(\\d+)?' +
				'(' +
				escapeRegExp('.') +
				'\\d+)?'; ///(-?\d+)(\.\d{0,2})?/
			var matchNumberRegexp = new RegExp(matchNumberString);

			//Perform regex
			var numberParts = numberInput
				.toString()
				.replace(replaceSymbolRegexp, '')
				.match(matchNumberRegexp);
			//If the field is empty then we don't want to write zero we set an empty value
			if (!numberParts || numberParts[0] === '') {
				return '';
			}

			numberString = numberParts[0];
			negativeValue = numberParts[1] || '';
			// integerValue = numberParts[2] || '0';
			// decimalValue = numberParts[3];

			number = Number(numberString);

			// decimalValue = pad(decimalValue, decimalPlaces + 1, '0');
			if (
				decimalPlaces !== '' &&
				decimalPlaces != null &&
				!preventRounding
			) {
				formattedNumber = round(number, decimalPlaces)
					.toFixed(precision)
					.replace(negativeValue, '')
					.split('.');
			} else if (precision) {
				formattedNumber = number
					.toFixed(precision)
					.replace(negativeValue, '')
					.split('.');
			} else {
				formattedNumber = numberString
					.replace(negativeValue, '')
					.split('.');
			}

			integerValue = formattedNumber[0] || '0';
			decimalValue = formattedNumber[1]
				? decimalCharacter + formattedNumber[1]
				: '';

			//Add thousands separator to integer
			if (thousandsSeparator) {
				integerValue = addThousandsSeparator(
					integerValue,
					thousandsSeparator
				);
			}

			formattedNumber = integerValue + decimalValue;
			if (noStyles) {
				return (
					negativeValue +
					numberLabelBefore +
					formattedNumber +
					escapeRegExp(preNumberLabelAfter) +
					numberLabelAfter
				);
			} else {
				return $sce.trustAsHtml(
					negativeValue +
						numberLabelBefore +
						formattedNumber +
						escapeRegExp(preNumberLabelAfter) +
						numberLabelAfter
				);
			}

			function round(value, decimals) {
				return Number(
					Math.round(value + 'e' + decimals) + 'e-' + decimals
				);
			}

			function addThousandsSeparator(numberString, separator) {
				//Function expects a string with no thousands separators in the string

				//apply formatting
				return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
			}

			function escapeRegExp(input) {
				if (!input) {
					return '';
				}
				return input.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
			}

			function pad(value, width, padchar) {
				while (value.length < width) {
					value += padchar;
				}
				return value;
			}
		}

		function getShareScheduleProperties(source, shareScheduleID, property) {
			if (!source) {
				return;
			}

			var sourceScheduleID = stringToID(shareScheduleID);
			var scheduleProperties;

			if (source[sourceScheduleID]) {
				scheduleProperties = getProperties(
					source[sourceScheduleID],
					property
				);
			} else {
				scheduleProperties = getProperties(source, property);
			}

			return scheduleProperties;

			function getProperties(parent, property) {
				if (property) {
					return parent[property];
				} else {
					var properties = {
						fieldMap: parent.fieldMap,
						labelMap: parent.labelMap,
						unusedMap: parent.unusedMap,
						customFields: parent.customFields,
					};
					return properties;
				}
			}
		}

		function getValidShareCustomFields(source, event) {
			if (!source) {
				return;
			}
			var customFields = source.customFields ? source.customFields : null;
			var sourceScheduleID = stringToID(event.shareScheduleID);
			var scheduleCustomFields;

			if (source[sourceScheduleID]) {
				scheduleCustomFields = source[sourceScheduleID].customFields;

				if (!customFields && scheduleCustomFields) {
					customFields = {};
				}

				for (var property in scheduleCustomFields) {
					customFields[property] = scheduleCustomFields[property];
				}
			}
			return customFields;
		}

		function humanJoin(input, objectName, quoteChar, objectNameToLower) {
			var translations = $translate.instant([
				'and',
				objectName,
				objectName + 's',
			]);
			var translatedObjectName =
				translations[objectName + (input.length > 1 ? 's' : '')];
			quoteChar = quoteChar || '';

			if (objectNameToLower) {
				translatedObjectName = translatedObjectName.toLowerCase();
			}

			if (input.length > 1) {
				return (
					quoteChar +
					input.slice(0, -1).join(quoteChar + ', ' + quoteChar) +
					(input.length > 2
						? quoteChar +
							', ' +
							translations['and'] +
							' ' +
							quoteChar
						: quoteChar +
							' ' +
							translations['and'] +
							' ' +
							quoteChar) +
					input.slice(-1) +
					(objectName
						? quoteChar + ' ' + translatedObjectName
						: quoteChar)
				);
			} else {
				return (
					quoteChar +
					input[0] +
					(objectName
						? quoteChar + ' ' + translatedObjectName
						: quoteChar)
				);
			}
		}

		function emailsFromString(text) {
			var separateEmailsBy = ', ';
			var email = ''; // if no match, use this
			var emailsArray = text.match(
				/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\\.[a-zA-Z0-9._-]+)/gi
			);
			if (emailsArray) {
				email = '';
				for (var i = 0; i < emailsArray.length; i++) {
					if (i != 0) email += separateEmailsBy;
					email += emailsArray[i];
				}
			}
			return email;
		}

		//helper function to convert a field map to a de-duped array of fields
		function mapToArray(schedule) {
			var fieldMap = schedule.fieldMap;
			var c;
			var i;
			var thisProp;
			var props = [];
			var newProp;
			var titleProperties = [];

			if (!schedule.unusedMap) {
				schedule.unusedMap = {};
			}

			for (c in fieldMap) {
				if (fieldMap[c] && !schedule.unusedMap[c]) {
					if (
						!fieldMap[c].allowedSchedules ||
						fieldMap[c].allowedSchedules.schedule.objectName
					) {
						thisProp = fieldMap[c].split(',');
						for (i in thisProp) {
							props.push(thisProp[i].trim());
						}
					}
				}
			}

			//strip out tags
			var firstSplit;
			var secondSplit;
			var thirdSplit;
			var splitProps = [];
			for (i = 0; i < props.length; i++) {
				firstSplit = props[i].split('>');
				if (firstSplit.length > 1) {
					var fieldArray = [];
					for (var ii = 0; ii < firstSplit.length; ii++) {
						secondSplit = firstSplit[ii].split('</');
						if (secondSplit.length > 1 && secondSplit[0]) {
							splitProps.push(secondSplit[0]);
						}
					}
				} else {
					splitProps.push(props[i]);
				}
			}
			var used = [];
			var result = [];
			for (c in splitProps) {
				newProp = splitProps[c];
				//don't add to query if already added or if designated as unused
				if (used.indexOf(newProp) === -1) {
					result.push(newProp);
					used.push(newProp);
				}
			}
			//sort this by descending length, so we don't sub out any substrings
			return result.sort(compareLength);

			function compareLength(a, b) {
				return b.length - a.length;
			}
		}
	}
})();
